
import { defineComponent, reactive, toRefs } from 'vue'
import UploadExcelComponent from '@/components/up-excel/Index.vue'
import BrandsPane from './components/BrandsPane.vue'
import { ElMessage } from 'element-plus'
import {
  addRange
} from '@/apis/brand'
export default defineComponent({
  components: {
    UploadExcelComponent,
    BrandsPane
  },
  setup() {
    const dataMap = reactive({
      activeName: 'uploadPane',
      tableData: [],
      tableHeader: Array<String>(),
      templateExcelUrl: '/Template/brands.xlsx',
      beforeUpload: (file: File) => {
        const isLt10M = file.size / 1024 / 1024 < 10
        if (isLt10M) {
          return true
        }
        ElMessage.warning('请不要上传大于10M的文件。')
        return false
      },
      handleSuccess: ({ results, header }: { results: any, header: string[] }) => {
        if (header[0] === '排序字母' && header[1] === '英文名称' && header[2] === '中文名称') {
          dataMap.tableData = results
          dataMap.tableHeader = header
          const brands: Array<any> = []
          dataMap.tableData.forEach((c) => {
            brands.push({
              indexLetter: c['排序字母'],
              name: c['英文名称'],
              cnName: c['中文名称'],
              status: 1
            })
          })
          addRange({ brands: brands })
          ElMessage({
            message: '数据上传成功',
            type: 'success'
          })
        } else {
          ElMessage({
            message: '模板错误，请检查',
            type: 'error'
          })
        }
      }
    })
    return { ...toRefs(dataMap) }
  }
})
