import https from '@/utils/https'
import { RootObject } from '@/model/rootObject'
import { ContentType, Method } from 'axios-mapper'
import { ModelList } from '@/model/modelList'

export const search = (params: any) => {
  return https().request<RootObject<ModelList<any>>>('brand/search', Method.GET, params, ContentType.json)
}

export const getBrands = (params: any) => {
  return https().request<RootObject<ModelList<any>>>('brand', Method.GET, params, ContentType.json)
}

export const addRange = (data: any) => {
  return https().request<RootObject<any[]>>('brand/addRange', Method.POST, data, ContentType.json)
}
