
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onMounted
} from 'vue'
import { ElForm } from 'element-plus'
import moment from 'moment-timezone'
import {
  search
} from '@/apis/vehicle'
import { VehicleModel } from '@/model/vehicleModel'
import { exportJson2Excel } from '@/utils/excel'
import { formatJson } from '@/utils'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup() {
    const { t } = useI18n()
    const dataForm = ref(ElForm)
    const dataMap = reactive({
      tableKey: 0,
      list: Array<VehicleModel>(),
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        pageSize: 10,
        vinCode: undefined,
        vesselName: undefined,
        voyageNo: undefined,
        brandName: undefined,
        damaged: null,
        startTime: undefined,
        endTime: ''
      },
      downLoadListQuery: {
        page: 1,
        pageSize: 5000,
        vinCode: undefined,
        vesselName: undefined,
        voyageNo: undefined,
        brandName: undefined,
        damaged: null,
        startTime: undefined,
        endTime: ''
      },
      statusOptions: [
        { label: '全部', key: null },
        { label: '无损坏', key: false },
        { label: '有损坏', key: true }
      ],
      pageviewsData: [],
      downloadLoading: false,
      formatTime(d: any) {
        moment.tz.setDefault('Asia/Shanghai')
        return d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : ''
      },
      handleCurrentChange(page?: any) {
        dataMap.getList(page)
      },
      handleSizeChange(val: any) {
        dataMap.getList(null, val)
      },
      async getList(index?: any, size?: any) {
        if (index) {
          dataMap.listQuery.page = index
        }
        if (size) {
          dataMap.listQuery.pageSize = size
        }
        dataMap.listLoading = true
        const data = await search(dataMap.listQuery)
        dataMap.list = data?.data.items ?? []
        dataMap.total = data?.data.total ?? 0
        setTimeout(() => {
          dataMap.listLoading = false
        }, 0.5 * 1000)
      },
      handleFilter() {
        if (dataMap.listQuery.endTime == null) {
          dataMap.listQuery.endTime = ''
        }

        if (dataMap.listQuery.endTime !== '') {
          dataMap.listQuery.endTime = moment(dataMap.listQuery.endTime).add(1, 'days').add(-1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        }
        dataMap.listQuery.page = 1
        dataMap.getList()
      },
      async handleDownload() {
        dataMap.downloadLoading = true
        const tHeader = ['车架号', '船名', '航次', '品牌', '电压(V)', '电量(%)', '状态', '检测日期']
        const filterVal = [
          'vinCode',
          'vesselName',
          'voyageNo',
          'brandName',
          'batteryVoltage',
          'batteryCapacity',
          'damaged',
          'createTime'
        ]

        dataMap.downLoadListQuery.vinCode = dataMap.listQuery.vinCode
        dataMap.downLoadListQuery.vesselName = dataMap.listQuery.vesselName
        dataMap.downLoadListQuery.voyageNo = dataMap.listQuery.voyageNo
        dataMap.downLoadListQuery.brandName = dataMap.listQuery.brandName
        dataMap.downLoadListQuery.damaged = dataMap.listQuery.damaged
        dataMap.downLoadListQuery.startTime = dataMap.listQuery.startTime
        dataMap.downLoadListQuery.endTime = dataMap.listQuery.endTime

        const items = await search(dataMap.downLoadListQuery)
        const data = formatJson(filterVal, items?.data.items ?? [])
        exportJson2Excel(tHeader, data, '检验记录')
        dataMap.downloadLoading = false
      }
    })
    onMounted(() => {
      dataMap.getList(null, 10)
    })
    return { t, ...toRefs(dataMap), dataForm }
  }
})
