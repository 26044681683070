import https from '@/utils/https'
import { RootObject } from '@/model/rootObject'
import { ContentType, Method } from 'axios-mapper'
import { VehicleModel } from '@/model/vehicleModel'
import { ModelList } from '@/model/modelList'

export const search = (params: any) => {
  return https().request<RootObject<ModelList<VehicleModel>>>('Vehicle/search2', Method.POST, params, ContentType.json)
}

export const getVin = (params: any) => {
  return https().request<RootObject<VehicleModel>>('Vehicle', Method.GET, params, ContentType.json)
}

export const addRange = (data: any) => {
  return https().request<RootObject<VehicleModel[]>>('Vehicle/addRange', Method.POST, data, ContentType.json)
}

export const update = (params: any) => {
  return https().request<RootObject<VehicleModel>>('Vehicle/update', Method.POST, params, ContentType.json)
}

export const deleteVin = (id: number) => {
  return https().request<RootObject<VehicleModel>>(`Vehicle/${id}`, Method.DELETE, undefined, ContentType.json)
}

export interface Pageviews{
  pageviews: any
}

export const getPageviews = (params: any) => {
  return https().request<RootObject<Pageviews>>('pageviews', Method.GET, params, ContentType.json)
}
