<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.vinCode"
        placeholder="请输入车架号"
        style="width: 160px"
        class="filter-item"
        @keyup.enter="handleFilter"
      />
      <el-input
        v-model="listQuery.vesselName"
        placeholder="请输入船名"
        style="width: 110px;margin-left: 10px"
        class="filter-item"
        @keyup.enter="handleFilter"
      />
      <el-input
        v-model="listQuery.voyageNo"
        placeholder="请输入航次"
        style="width: 110px;margin-left: 10px"
        class="filter-item"
        @keyup.enter="handleFilter"
      />
      <el-input
        v-model="listQuery.brandName"
        placeholder="请输入品牌"
        style="width: 110px;margin-left: 10px"
        class="filter-item"
        @keyup.enter="handleFilter"
      />
      <el-select
        v-model="listQuery.damaged"
        style="width: 140px;margin-left: 10px"
        class="filter-item"
        @change="handleFilter"
      >
        <el-option
          v-for="item in statusOptions"
          :key="item.key"
          :label="item.label"
          :value="item.key"
        />
      </el-select>
      <el-date-picker
        v-model="listQuery.startTime"
        style="width: 170px;margin-left: 10px"
        class="filter-item"
        placeholder="开始日期"
        format="YYYY年MM月DD日"
        value-format="YYYY-MM-DD"
        type="date"
      />
      <el-date-picker
        v-model="listQuery.endTime"
        style="width: 170px;margin-left: 10px"
        class="filter-item"
        placeholder="结束日期"
        format="YYYY年MM月DD日"
        value-format="YYYY-MM-DD"
        type="date"
      />
      <el-button
        v-waves
        class="filter-item"
        style="margin-left: 10px"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
      >
        {{ t("table.search") }}
      </el-button>
      <el-button
        v-waves
        :loading="downloadLoading"
        class="filter-item"
        type="primary"
        icon="el-icon-download"
        @click="handleDownload"
      >
        {{ t("table.export") }}
      </el-button>
    </div>
    <el-table
      :key="tableKey"
      v-loading="listLoading"
      :data="list"
      fit
      highlight-current-row
      empty-text="暂无数据"
      style="width:100%"
    >
      <el-table-column
        :label="t('table.id')"
        prop="id"
        align="center"
        width="80px"
      >
        <template #default="{row}">
          <span>{{ (listQuery.page-1)*listQuery.pageSize+list.indexOf(row) + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="t('table.vin')"
      >
        <template #default="{row}">
          <span>{{
            row.vinCode
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="t('table.shipName')"
        width="180px"
        align="center"
      >
        <template #default="{row}">
          <span>{{ row.vesselName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="t('table.voyageNumber')"
        width="180px"
        align="center"
      >
        <template #default="{row}">
          <span>{{ row.voyageNo }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="t('table.brand')"
        width="180px"
        align="center"
      >
        <template #default="{row}">
          <span>{{ row.brandName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="t('table.batteryVoltage')"
        width="180px"
        align="right"
      >
        <template #default="{row}">
          <span>{{ row.batteryVoltage }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="t('table.batteryCapacity')"
        width="180px"
        align="right"
      >
        <template #default="{row}">
          <span>{{ row.batteryCapacity }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="t('table.status')"
        class-name="status-col"
        width="100px"
      >
        <template #default="{row}">
          <el-tag :type="row.damaged">
            {{ row.damaged ? '有损坏':'无损坏' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        :label="t('table.inspectTime')"
        class-name="status-col"
        width="160px"
      >
        <template #default="{row}">
          {{ formatTime(row?.createTime) }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      v-show="total > 0"
      v-model:page="listQuery.page"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 50, 100]"
      layout="total, sizes, prev, pager, next, jumper"
    />
  </div>
</template>

<script lang='ts'>
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onMounted
} from 'vue'
import { ElForm } from 'element-plus'
import moment from 'moment-timezone'
import {
  search
} from '@/apis/vehicle'
import { VehicleModel } from '@/model/vehicleModel'
import { exportJson2Excel } from '@/utils/excel'
import { formatJson } from '@/utils'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup() {
    const { t } = useI18n()
    const dataForm = ref(ElForm)
    const dataMap = reactive({
      tableKey: 0,
      list: Array<VehicleModel>(),
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        pageSize: 10,
        vinCode: undefined,
        vesselName: undefined,
        voyageNo: undefined,
        brandName: undefined,
        damaged: null,
        startTime: undefined,
        endTime: ''
      },
      downLoadListQuery: {
        page: 1,
        pageSize: 5000,
        vinCode: undefined,
        vesselName: undefined,
        voyageNo: undefined,
        brandName: undefined,
        damaged: null,
        startTime: undefined,
        endTime: ''
      },
      statusOptions: [
        { label: '全部', key: null },
        { label: '无损坏', key: false },
        { label: '有损坏', key: true }
      ],
      pageviewsData: [],
      downloadLoading: false,
      formatTime(d: any) {
        moment.tz.setDefault('Asia/Shanghai')
        return d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : ''
      },
      handleCurrentChange(page?: any) {
        dataMap.getList(page)
      },
      handleSizeChange(val: any) {
        dataMap.getList(null, val)
      },
      async getList(index?: any, size?: any) {
        if (index) {
          dataMap.listQuery.page = index
        }
        if (size) {
          dataMap.listQuery.pageSize = size
        }
        dataMap.listLoading = true
        const data = await search(dataMap.listQuery)
        dataMap.list = data?.data.items ?? []
        dataMap.total = data?.data.total ?? 0
        setTimeout(() => {
          dataMap.listLoading = false
        }, 0.5 * 1000)
      },
      handleFilter() {
        if (dataMap.listQuery.endTime == null) {
          dataMap.listQuery.endTime = ''
        }

        if (dataMap.listQuery.endTime !== '') {
          dataMap.listQuery.endTime = moment(dataMap.listQuery.endTime).add(1, 'days').add(-1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        }
        dataMap.listQuery.page = 1
        dataMap.getList()
      },
      async handleDownload() {
        dataMap.downloadLoading = true
        const tHeader = ['车架号', '船名', '航次', '品牌', '电压(V)', '电量(%)', '状态', '检测日期']
        const filterVal = [
          'vinCode',
          'vesselName',
          'voyageNo',
          'brandName',
          'batteryVoltage',
          'batteryCapacity',
          'damaged',
          'createTime'
        ]

        dataMap.downLoadListQuery.vinCode = dataMap.listQuery.vinCode
        dataMap.downLoadListQuery.vesselName = dataMap.listQuery.vesselName
        dataMap.downLoadListQuery.voyageNo = dataMap.listQuery.voyageNo
        dataMap.downLoadListQuery.brandName = dataMap.listQuery.brandName
        dataMap.downLoadListQuery.damaged = dataMap.listQuery.damaged
        dataMap.downLoadListQuery.startTime = dataMap.listQuery.startTime
        dataMap.downLoadListQuery.endTime = dataMap.listQuery.endTime

        const items = await search(dataMap.downLoadListQuery)
        const data = formatJson(filterVal, items?.data.items ?? [])
        exportJson2Excel(tHeader, data, '检验记录')
        dataMap.downloadLoading = false
      }
    })
    onMounted(() => {
      dataMap.getList(null, 10)
    })
    return { t, ...toRefs(dataMap), dataForm }
  }
})
</script>
