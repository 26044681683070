<template>
  <div class="tab-container">
    <el-tabs
      v-model="activeName"
      style="margin-top: 15px"
      type="border-card"
    >
      <el-tab-pane
        label="数据上传"
        name="uploadPane"
      >
        <a
          :href="templateExcelUrl"
          target="_blank"
        ><el-tag type="info">点击此处，下载上传模板</el-tag></a>
        <div v-if="activeName === 'uploadPane'">
          <UploadExcelComponent
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
          />
          <el-table
            :data="tableData"
            highlight-current-row
            fit
            empty-text="暂无数据"
            style="width: 100%;margin-top:20px;"
          >
            <el-table-column
              v-for="item of tableHeader"
              :key="item"
              :prop="item"
              :label="item"
            />
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="品牌列表"
        name="managePane"
      >
        <BrandsPane v-if="activeName === 'managePane'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
import UploadExcelComponent from '@/components/up-excel/Index.vue'
import BrandsPane from './components/BrandsPane.vue'
import { ElMessage } from 'element-plus'
import {
  addRange
} from '@/apis/brand'
export default defineComponent({
  components: {
    UploadExcelComponent,
    BrandsPane
  },
  setup() {
    const dataMap = reactive({
      activeName: 'uploadPane',
      tableData: [],
      tableHeader: Array<String>(),
      templateExcelUrl: '/Template/brands.xlsx',
      beforeUpload: (file: File) => {
        const isLt10M = file.size / 1024 / 1024 < 10
        if (isLt10M) {
          return true
        }
        ElMessage.warning('请不要上传大于10M的文件。')
        return false
      },
      handleSuccess: ({ results, header }: { results: any, header: string[] }) => {
        if (header[0] === '排序字母' && header[1] === '英文名称' && header[2] === '中文名称') {
          dataMap.tableData = results
          dataMap.tableHeader = header
          const brands: Array<any> = []
          dataMap.tableData.forEach((c) => {
            brands.push({
              indexLetter: c['排序字母'],
              name: c['英文名称'],
              cnName: c['中文名称'],
              status: 1
            })
          })
          addRange({ brands: brands })
          ElMessage({
            message: '数据上传成功',
            type: 'success'
          })
        } else {
          ElMessage({
            message: '模板错误，请检查',
            type: 'error'
          })
        }
      }
    })
    return { ...toRefs(dataMap) }
  }
})
</script>

<style lang="scss" scoped>
.tab-container {
  margin: 30px;
}
</style>
